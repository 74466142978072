export enum THEME_MODE {
  DARK = 'dark',
  LIGHT = 'light'
}

export enum GISCUS_THEME {
  DARK = 'dark_dimmed',
  LIGHT = 'light',
}

export enum BOOLEAN_STRING {
  TRUE = '1',
  FALSE = '0'
}
